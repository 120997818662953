<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="0px">
            <div class="Industry-box">
                <div class="auxiliary-line">
                    <p>1、请以不超过500字简个你的主要事业成就，包括你在主要工作项目上曾担当的角色、你工作期间曾服务的知名机构/单位或你对有关工作项目及机构的重大贡献。</p>
                    <el-form-item class="same-line" prop="career_achievement" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.career_achievement"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
                <div class="auxiliary-line">
                    <p>2、请以不超过500字简介你的专业技能或人才清单内相关专业的成就，以及有关技能 /专业如何对香港经济或社会作出重大贡献。</p>
                    <el-form-item class="same-line" prop="professional_skills" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.professional_skills"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
                <div class="auxiliary-line">
                    <p>3、请以不超过500字简介你的学业成就，包括你的学业成绩或曾取得的整项(如有)。</p>
                    <el-form-item class="same-line" prop="academic_achievement" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.academic_achievement"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
                <div class="auxiliary-line">
                    <p>4、请以不超过500字简介你未来在香港发展事业的计划，包括你与本地商业或学术机构联系及合作的详情(如有)。</p>
                    <el-form-item class="same-line" prop="plan_detail" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.plan_detail"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
                <div class="auxiliary-line">
                    <p>5、请以不超过500字简介你在所属的专业界别内曾获取由国家或国际认可/著名的团体颁授的专业资格，及曾取得的奖项(如有)。</p>
                    <el-form-item class="same-line" prop="qualifications_and_awards" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.qualifications_and_awards"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
                <div class="auxiliary-line">
                    <p>6、请以不超过500字简介你认为对评核你的申请非常重要的其他资料。</p>
                    <el-form-item class="same-line" prop="other" :rules="[{required: true,message: '请输入',trigger: 'change'}]">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.other"
                            maxlength="500"
                            show-word-limit
                        />
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            userForm: {},
        };
    },
    created() {
        this.userForm = {
            ...this.data
        };
    },
    methods: {
        // 校验
        save () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate( valid => {
                    if (valid) {
                        resolve(valid)
                    } else {
                        reject();
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.auxiliary-line > p {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    color: #606266;
    &.no-required {
        &::before{
            content: "";
        }
    }
    &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 2px;
        color: red;
    }
}
.industry-box {
    padding: 0 16px;
    position: relative;

    /deep/.el-radio__label {
        display: none;
    }
}
</style>
