<!--
 * @Description: 事业或学业成就及未来计划
-->
<template>
    <div>
        <Container title="事业或学业成就及未来计划" padding="15px 15px 20px 15px">
            <!-- 回显信息 -->
            <Detail v-if="!isEdit" :data="data"/>
            <!-- 编辑 -->
            <Edit ref="editForm" v-if="isEdit" v-loading="isLoading" :data="data"/>
            <!-- <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                    <el-button v-if="isEdit" @click="cancel">取消</el-button>
                    <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="saveSuccess">保存</el-button>
                </div>
            </div> -->
        </Container>
    </div>
</template>

<script>
import Detail from './C/Detail.vue'
import Edit from './C/Edit.vue'
import { postClientInfo } from '@/api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        Detail,
        Edit
    },
    data() {
        return {
            isEdit: false,
            oldData: {},
            isLoading: false,
        }
    },
    methods: {
        editInfo() {
            this.isEdit = true;
            this.oldData = JSON.parse(JSON.stringify(this.data))
        },
        // 保存成功
        saveSuccess() {
            const edit = this.$refs['editForm'].save();
            Promise.all([edit]).then(() => {
                //全部验证通过就会走这里
                this.postData()
            }, err => {
                this.$utils.scrollIntoError(this);
            })
        },
        async postData() {
            this.isLoading = true;
            try {
                const { code } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message.success("保存成功！");
                    this.isEdit = false;
                }
            } finally {
                this.isLoading = false
            }
        },
        cancel() {
            this.data = JSON.parse(JSON.stringify(this.oldData));
            this.isEdit = false;
        }
    },
}
</script>
