<template>
    <div class="pdt-20">
        <p>1、请以不超过500字简个你的主要事业成就，包括你在主要工作项目上曾担当的角色、你工作期间曾服务的知名机构/单位或你对有关工作项目及机构的重大贡献。</p>
        <div>{{data.career_achievement || "-"}}</div>
        <el-divider></el-divider>
        <p>2、请以不超过500字简介你的专业技能或人才清单内相关专业的成就，以及有关技能 /专业如何对香港经济或社会作出重大贡献。</p>
        <div>{{data.professional_skills || "-"}}</div>
        <el-divider></el-divider>
        <p>3、请以不超过500字简介你的学业成就，包括你的学业成绩或曾取得的整项(如有)。</p>
        <div>{{data.academic_achievement || "-"}}</div>
        <el-divider></el-divider>
        <p>4、请以不超过500字简介你未来在香港发展事业的计划，包括你与本地商业或学术机构联系及合作的详情(如有)。</p>
        <div>{{data.plan_detail || "-"}}</div>
        <el-divider></el-divider>
        <p>5、请以不超过500字简介你在所属的专业界别内曾获取由国家或国际认可/著名的团体颁授的专业资格，及曾取得的奖项(如有)。</p>
        <div>{{data.qualifications_and_awards || "-"}}</div>
        <el-divider></el-divider>
        <p>6、请以不超过500字简介你认为对评核你的申请非常重要的其他资料。</p>
        <div>{{data.other || "-"}}</div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
}
</script>
<style lang="less" scoped>
    p {
        color: #999;
        margin-bottom: 20px;
    }
</style>
