<!--
 * @Author: zhai
 * @Description: 人才清单问题
-->
<template>
    <Container title="人才清单" padding="0" id="talent_listing">
        <div>
            <div class="description-box" id="talent_listing-score">
                <p>{{ info.component_score.talent_listing.answer }}，得分<span class="text-red">{{ info.component_score.talent_listing.score }}</span>分</p>
            </div>
            <div style="padding: 15px">
                <p id="talent_listing-name">1，是否符合人才清单<span style="color: red">（人才清单请查阅：https://www.talentlist.gov.hk/sc/）</span></p>
                <div v-if="!isEdit">
                    <span v-if="info.talent_listing && info.talent_listing.option === 1" class="mt-20">符合 - </span>
                    <span v-else-if="info.talent_listing && info.talent_listing.option === 0" class="mt-20">不符合</span>
                    <span v-else> - </span>
                    <span class="mt-20" style="color: red">{{ info.talent_listing.remark }}</span>
                </div>
                <el-form v-if="isEdit" :model="ruleForm" ref="ruleForm" inline label-width="0" class="mt-20">
                    <el-form-item label-width="0" prop="option" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="ruleForm.option">
                            <el-radio :label="0">不符合</el-radio>
                            <el-radio :label="1">符合</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.option === 1" label-width="0" prop="remark_list" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-cascader :options="info.selector" :show-all-levels="false" :props="{label: 'name', value: 'name', children: 'example'}" ref="inventory" v-model="ruleForm.remark_list">
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div slot="handle">
            <div class="handle-btn mr-15">
                <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                <el-button v-if="isEdit" @click="cancel">取消</el-button>
                <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="saveSuccess">保存</el-button>
            </div>
        </div>
    </Container>
</template>

<script>
import { postClientInfo } from '../../../../api/gifted'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            isEdit: false,
            ruleForm: {
                option: null,
            },
            oldData: {},
            isLoading: false,
        }
    },
    mounted() {
        // 初始化ruleForm
        this.initRuleForm()
    },
    methods: {
        findName(arr) {
            for(let i = 0; i < arr.length; i++) {
                for(let j = 0; j < arr[i].example.length; j++) {
                    if (arr[i].example[j].name === this.info.talent_listing.remark) {
                        return [arr[i].name, arr[i].example[j].name]
                    }
                }
            }
        },
        initRuleForm () {
            if (this.info.talent_listing.option === 0 || this.info.talent_listing.remark === '') {
                this.ruleForm = this.info.talent_listing
            } else {
                const res = this.findName(this.info.selector) || ''
                this.ruleForm = Object.assign(this.info.talent_listing, {
                    remark_list: res
                })
            }
        },
        editInfo() {
            this.isEdit = true;
            this.oldData = JSON.parse(JSON.stringify(this.ruleForm));
        },
        async saveSuccess() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    this.info.talent_listing = this.ruleForm;
                    if (this.info.talent_listing.option === 0) {
                        this.info.talent_listing.remark = ''
                    } else {
                        this.info.talent_listing.remark = this.$refs.inventory.getCheckedNodes()[0].label
                    }
                    try {
                        this.isLoading = true;
                        const { code, data } = await postClientInfo(this.info )
                        if (code === 200) {
                            this.$message.success("保存成功！");
                            this.isEdit = false;
                            this.info.component_score = data.component_score;
                            this.info.talent_listing = data.talent_listing;
                            this.isLoading = false;
                        }
                    } finally {
                        this.isLoading = false
                    }
                }
            })
        },
        cancel() {
            this.isEdit = false;
            this.ruleForm = JSON.parse(JSON.stringify(this.oldData));
            this.info.talent_listing = JSON.parse(JSON.stringify(this.oldData));
        },
    },
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    .title-btn {
        position: absolute;
        top: 4px;
        right: 15px;
    }
}
#talent_listing-name{
    color: #999;
    margin-bottom: 20px;
}
</style>
