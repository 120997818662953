<!--
 * @Author: zhai
 * @Description: 负债
-->
<template>
    <div>
        <Container title="负债" padding="15px 15px 20px 15px" id="user_liabilities">
            <el-table
                :data="property.liabilities"
                border
                style="width: 100%;"
                :row-style="{height: '40px'}"
            >
                <el-table-column
                    prop="item"
                    label="说明（例如：银行存款 / 房地产 / 证券 / 所拥有的私企企业名称）"
                    align="center"
                    id="liabilities_explain"
                >
                    <template slot-scope="scope">
                        <div v-show="!isEdit">
                            {{ scope.row.item }}
                        </div>
                        <div v-show="isEdit" >
                            <el-input v-model="scope.row.item" placeholder="请输入" clearable />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="hkd"
                    label="金额（港元）"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div v-show="!isEdit">{{scope.row.hkd ? scope.row.hkd.replace(/,/g, '') : '' }}</div>
                        <div v-show="isEdit" >
                            <el-input v-model="scope.row.hkd"
                                maxlength="14"
                                placeholder="保留小数点后两位"
                                clearable
                                oninput="value=value.match(/^[\d,]*(\.?\d{0,2})/g)[0] || null" onblur="value=value.replace(/,/g, '')"
                                @change="change($event, scope)"
                            />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-show="!isEdit" type="primary" @click="edit">编辑</el-button>
                    <el-button v-show="isEdit" @click="cancel">取消</el-button>
                    <el-button v-show="isEdit" :loading="isLoading" type="primary" @click="save">保存</el-button>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { postClientInfo } from '../../../../api/gifted'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            isEdit: false,
            isLoading: false,
        }
    },
    created() {
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'property', data: JSON.parse(JSON.stringify(this.info)) })
    },
    computed: {
        ...mapState('previewPdfGifted', ['property'])
    },
    mounted() {
        console.log(this.info.liabilities)
    },
    methods: {
        change (val, row) {
            this.property.liabilities[row.$index].hkd = val
        },
        edit() {
            this.isEdit = true;
            this.oldData = JSON.parse(JSON.stringify(this.info.liabilities))

            // 编辑时,不允许编辑合计
            this.property.liabilities.forEach((item, index) => {
                if (item.item == '合计') {
                    this.property.liabilities.splice(index, 1)
                }
            });
        },
        async save() {
            try {
                this.isLoading = true;
                for (let i = 0; i < this.property.liabilities.length; i++) {
                    if (this.property.liabilities[i].item && !this.property.liabilities[i].hkd) {
                        return this.$message.error('负债第' + (i + 1) + '行不能为空');
                    }
                    if (!this.property.liabilities[i].item && this.property.liabilities[i].hkd) {
                        return this.$message.error('负债第' + (i + 1) + '行不能为空');
                    }
                }
                // 提交
                this.property.property.forEach((item, index) => {
                    if (item.item == '合计' && item.id == '') {
                        this.property.property.splice(index, 1)
                    }
                });

                this.property.liabilities.forEach((item, index) => {
                    if (item.item == '合计' && item.id == '') {
                        this.property.liabilities.splice(index, 1)
                    }
                });

                const { code } = await postClientInfo(this.property)
                if (code === 200) {
                    this.$message.success("保存成功！");
                    // 保存数据后,需要重新请求接口返回合计的结果刷新页面
                    await this.$parent.getData()
                    this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'property', data: JSON.parse(JSON.stringify(this.$parent.dataList[7]))})
                    this.isEdit = false;
                }
            } finally {
                this.isLoading = false
            }
            this.$utils.emptyModuleRed(this, 'pdf-bg3')
        },
        cancel() {
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'property', data: JSON.parse(JSON.stringify(this.info)) })
            this.isEdit = false;
            // this.info.liabilities = JSON.parse(JSON.stringify(this.oldData));
            this.$utils.emptyModuleRed(this, 'pdf-bg3')
        }
    }
}
</script>

<style lang="less" scoped>
</style>
